/**
* Project Gallery JS
* -----------------------------------------------------------------------------
*
* All the JS for the Project Gallery component.
*/

/*
 * Example of importing modules if needed. Like for scroll magic / gsap
 */

// import {gsap} from 'gsap';
// import {ScrollTrigger} from 'gsap/ScrollTrigger.js';
( function( app ) {
  const COMPONENT = {

    className: 'project-gallery-grid',
    element: null,
    selector: function() {
      return '.' + this.className;
    },
    // Fires after common.init, before .finalize and common.finalize
    init: function() {
      const _this = this;
      _this.element = $( '.project-gallery-grid' );
      _this.loadPopupGallery( );
      const urlPageParamIndex = window.location.pathname.indexOf( 'page' );
      const baseURL = urlPageParamIndex !== -1 ? window.location.pathname.substring( 0, urlPageParamIndex ) : window.location.pathname;

      /* Handle Mobile Display */
      $( document ).on( 'click', '.project-gallery__mobile-filter-btn', ( event ) => {
        $( event.target ).next().slideToggle();
      } );

      $( document ).on( 'updateMediaQuery', ( event ) => {
        $( '.gallery-project__form-wrapper' ).get( 0 ).style.removeProperty( 'display' );
      } );

      /* Clear filter button */
      $( document ).on( 'click', '.project-form__clear-filter', function( event ) {
        _this.filter( baseURL );
      } );

      if ( _this.element ) {
        const $form = _this.element.find( 'form' );

        $( document ).on( 'change', '.js-project-form', function( event ) {
          event.preventDefault();
          const formData = $( this ).serialize();
          const url = `${ baseURL }?${formData}`;
          _this.filter( url );
          return false;
        } );
      }

      window.addEventListener( 'popstate', function( e ) {
        _this.filter( window.location.href );
      } );
    },
    filter: function( url ) {
      const _this = this;

      $.ajax( {
        type: 'GET',
        url: url,
        success: function( data ) {
          const grid = $( data ).find( '.project-gallery-grid' );

          _this.element.replaceWith( grid );
          _this.element = grid;
          _this.loadPopupGallery( );
          history.pushState( null, '', `${url}` );
        },
      } );
    },
    loadPopupGallery: function( ) {
      $( '.project-gallery-grid__grid' ).magnificPopup( {
        delegate: 'a',
        type: 'image',
        tLoading: 'Loading image #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
          enabled: true,
          navigateByImgClick: true,
          preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
        },
        image: {
          tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
        },
      } );
    },
    finalize: function() {
    },
  };

  // Hooks the component into the app
  app.registerComponent( 'project-gallery-grid', COMPONENT );
} )( app );
