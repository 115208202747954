/**
* Images Gallery JS
* -----------------------------------------------------------------------------
*
* All the JS for the Images Gallery component.
*/

/*
 * Example of importing modules if needed. Like for scroll magic / gsap
 */

// import {gsap} from 'gsap';
// import {ScrollTrigger} from 'gsap/ScrollTrigger.js';
( function( app ) {
  const COMPONENT = {

    className: 'images-gallery',
    selector: function() {
      return '.' + this.className;
    },
    // Fires after common.init, before .finalize and common.finalize
    init: function() {
      // gsap.registerPlugin( ScrollTrigger );
      $( '.images-gallery' ).each( function( key, component ) {
        $( component ).find( '.primary-slider' ).slick( {
          dots: false,
          arrows: true,
          slidesToShow: 1,
          autoplay: true,
          autoplaySpeed: 5000,
          asNavFor: $( component ).find( '.secondary-slider' ),
        } );

        $( component ).find( '.secondary-slider' ).slick( {
          dots: false,
          arrows: false,
          slidesToShow: 4,
          vertical: true,
          verticalSwiping: true,
          asNavFor: $( component ).find( '.primary-slider' ),
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                vertical: false,
                verticalSwiping: false,
              },
            },
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 3,
                vertical: false,
                verticalSwiping: false,
              },
            },
          ],
        } );

        $( component ).find( '.secondary-slider .slide' ).on( 'click', function() {
          $( component ).find( '.primary-slider' ).slick( 'slickGoTo', $( this ).attr( 'data-slick-index' ) );
        } );
      } );
    },
    finalize: function() {
    },
  };

  // Hooks the component into the app
  app.registerComponent( 'images-gallery', COMPONENT );
} )( app );
