/**
* Vertical Tabs JS
* -----------------------------------------------------------------------------
*
* All the JS for the Vertical Tabs component.
*/

/*
 * Example of importing modules if needed. Like for scroll magic / gsap
 */

// import {gsap} from 'gsap';
// import {ScrollTrigger} from 'gsap/ScrollTrigger.js';
// gsap.registerPlugin( ScrollTrigger );
( function( app ) {
  const COMPONENT = {

    className: 'vertical-tabs',
    selector: function() {
      return '.' + this.className;
    },
    // Fires after common.init, before .finalize and common.finalize
    init: function() {
      const verticalTabsItem = $( '.vertical-tabs__item' );
      verticalTabsItem.on( 'click', function() {
        if ( window.matchMedia( '(min-width: 1024px)' ).matches ) {
          if ( $( this ).hasClass( 'active' ) ) {
            return;
          } else {
            verticalTabsItem.removeClass( 'active' );
            $( this ).addClass( 'active' );
          }
        }
      } );

      $( '.vertical-tabs__next' ).on( 'click', function() {
        const currentItem = $( this ).parents( '.vertical-tabs__item' );
        const item = parseInt( currentItem.attr( 'data-item' ) );
        const nextItem = $( this ).parents( '.vertical-tabs' ).find( `.vertical-tabs__item[data-item="${item + 1}"]` );

        // show the next itme
        $( nextItem ).addClass( 'active' );
        $( this ).parents( '.vertical-tabs__item' ).removeClass( 'active' );
      } );

      $( '.vertical-tabs__prev' ).on( 'click', function() {
        const currentItem = $( this ).parents( '.vertical-tabs__item' );
        const item = parseInt( currentItem.attr( 'data-item' ) );
        const prevItem = $( this ).parents( '.vertical-tabs' ).find( `.vertical-tabs__item[data-item="${item - 1}"]` );

        // show the prev itme
        $( prevItem ).addClass( 'active' );
        $( this ).parents( '.vertical-tabs__item' ).removeClass( 'active' );

        prevItem.addClass( 'active' );
        $( this ).parents( '.vertical-tabs__item' ).removeClass( 'active' );
      } );
    },
    finalize: function() {
    },
  };

  // Hooks the component into the app
  app.registerComponent( 'vertical-tabs', COMPONENT );
} )( app );
