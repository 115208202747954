/**
* Hero Banner JS
* -----------------------------------------------------------------------------
*
* All the JS for the Hero Banner component.
*/

/*
 * Example of importing modules if needed. Like for scroll magic / gsap
 */
// import {gsap} from 'gsap';
import {Splide} from '@splidejs/splide';
import {AutoScroll} from '@splidejs/splide-extension-auto-scroll';
// import {ScrollTrigger} from 'gsap/ScrollTrigger.js';
( function( app ) {
  const COMPONENT = {

    className: 'hero-banner',
    selector: function() {
      return '.' + this.className;
    },
    // Fires after common.init, before .finalize and common.finalize
    init: function() {
      if ( $( '.hero-banner__highlights' ).length ) {
        $( '.hero-banner__highlights' ).each( function( index, element ) {
          new Splide( element, {
            type: 'loop',
            gap: '0rem',
            autoScroll: {
              speed: 0.75,
              pauseOnHover: true,
              pauseOnFocus: true,
            },
            arrows: false,
            pagination: false,
            mediaQuery: 'min',
            breakpoints: {
              640: {
                destroy: true,
              },
            },
          } ).mount( {AutoScroll} );
        } );
      }
    },
    finalize: function() {
    },
  };

  // Hooks the component into the app
  app.registerComponent( 'hero-banner', COMPONENT );
} )( app );
