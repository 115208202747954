/**
* Logo Marquee JS
* -----------------------------------------------------------------------------
*
* All the JS for the Logo Marquee component.
*/

/*
 * Example of importing modules if needed. Like for scroll magic / gsap
 */

// import {gsap} from 'gsap';
// import {ScrollTrigger} from 'gsap/ScrollTrigger.js';
// gsap.registerPlugin( ScrollTrigger );
import Splide from '@splidejs/splide';
import {AutoScroll} from '@splidejs/splide-extension-auto-scroll';
( function( app ) {
  const COMPONENT = {

    className: 'logo-marquee',
    selector: function() {
      return '.' + this.className;
    },
    // Fires after common.init, before .finalize and common.finalize
    init: function() {
      if ( $( '.logo-marquee__splide' ).length ) {
        $( '.logo-marquee__splide' ).each( function( index, element ) {
          const slider = new Splide( $( element )[0], {
            type: 'loop',
            drag: 'free',
            focus: 'center',
            perPage: 1,
            gap: '80px',
            autoWidth: true,
            arrows: false,
            pagination: false,
            autoScroll: {
              speed: .75,
              pauseOnFocus: false,
              pauseOnHover: false,
            },
          } );
          slider.mount( {AutoScroll} );
        } );
      }
    },
    finalize: function() {
    },
  };

  // Hooks the component into the app
  app.registerComponent( 'logo-marquee', COMPONENT );
} )( app );
