/**
* Testimonial Slider JS
* -----------------------------------------------------------------------------
*
* All the JS for the Testimonial Slider component.
*/

/*
 * Example of importing modules if needed. Like for scroll magic / gsap
 */

// import {gsap} from 'gsap';
// import {ScrollTrigger} from 'gsap/ScrollTrigger.js';
import Splide from '@splidejs/splide';
( function( app ) {
  const COMPONENT = {

    className: 'testimonial-slider',
    selector: function() {
      return '.' + this.className;
    },
    // Fires after common.init, before .finalize and common.finalize
    init: function() {
      if ( $( '.testimonial-slider__splide' ).length ) {
        $( '.testimonial-slider__splide' ).each( function( index, element ) {
          new Splide( element, {
            type: 'loop',
            gap: '2rem',
            perMove: 1,
            perPage: 2,
            pagination: false,
            speed: 800,
            breakpoints: {
              768: {
                perPage: 1,
              },
            },
          } ).mount( {} );
        } );
      }

      $( '.js-init-popup' ).magnificPopup( {
        type: 'inline',
        fixedContentPos: true,
        fixedBgPos: true,
        overflowY: 'auto',
        closeBtnInside: false,
        preloader: false,
        midClick: true,
        removalDelay: 300,
      } );
      $( document ).on( 'click', '.testimonial-slider__dialog-close', function( e ) {
        e.preventDefault();
        $.magnificPopup.close();
      } );
    },
    finalize: function() {
    },
  };

  // Hooks the component into the app
  app.registerComponent( 'testimonial-slider', COMPONENT );
} )( app );
