/**
* Visual Form CTA JS
* -----------------------------------------------------------------------------
*
* All the JS for the Visual Form CTA component.
*/

/*
 * Example of importing modules if needed. Like for scroll magic / gsap
 */

import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger.js';
gsap.registerPlugin( ScrollTrigger );
( function( app ) {
  const COMPONENT = {

    className: 'visual-form-cta',
    selector: function() {
      return '.' + this.className;
    },
    // Fires after common.init, before .finalize and common.finalize
    init: function() {
      $( '.visual-form-cta' ).each( function() {
        gsap.registerPlugin( ScrollTrigger );
        const tl = gsap.timeline( {
          scrollTrigger: {
            trigger: this,
            start: 'top 90%',
            scrub: 0.15,
            // markers: true,
            onRefresh: ( self ) => {
              if ( self.progress > 0 ) {
                $( this ).addClass( 'is-loaded' );
              }
            },
            onEnter: ( {progress, direction, isActive} ) => $( this ).addClass( 'is-loaded' ),
          },
        } );
      } );
    },
    finalize: function() {
    },
  };

  // Hooks the component into the app
  app.registerComponent( 'visual-form-cta', COMPONENT );
} )( app );
