/**
* Content Grid JS
* -----------------------------------------------------------------------------
*
* All the JS for the Content Grid component.
*/

/*
 * Example of importing modules if needed. Like for scroll magic / gsap
 */

// import {gsap} from 'gsap';
// import {ScrollTrigger} from 'gsap/ScrollTrigger.js';
( function( app ) {
  const COMPONENT = {

    className: 'announcement-banner',
    selector: function() {
      return '.' + this.className;
    },
    // Fires after common.init, before .finalize and common.finalize
    init: function() {
      function closeAnnouncementBanner() {
        $( '#announcement-banner' ).slideUp( 0, function() {
          $( ':root' ).css( '--announcementBannerHeight', `0px` );
        } );
      }
      function openAnnouncementBanner() {
        $( '#announcement-banner' ).slideDown( 0, function() {
          const bannerHeight = $( '#announcement-banner' ).outerHeight();
          $( ':root' ).css( '--announcementBannerHeight', `${bannerHeight}px` );
        } );
        announcementBannerHeight();
      }
      function announcementBannerHeight() {
        if ( $( '#announcement-banner:not([style*="display:none;"])' ).length ) {
          const bannerHeight = $( '#announcement-banner' ).outerHeight();
          $( ':root' ).css( '--announcementBannerHeight', `${bannerHeight}px` );
        }
      }

      announcementBannerHeight();
      $( 'window' ).on( 'resize', function() {
        announcementBannerHeight();
      } );

      if ( !sessionStorage.getItem( 'll-announcement-banner-seen' ) ) {
        sessionStorage.setItem( 'll-announcement-banner-seen', 'false' );
      }
      if ( sessionStorage.getItem( 'll-announcement-banner-seen' ) === 'false' ) {
        openAnnouncementBanner();
      }

      $( '#announcement-banner__close' ).on( 'click', function() {
        closeAnnouncementBanner();
        sessionStorage.setItem( 'll-announcement-banner-seen', 'true' );
      } );
    },
    finalize: function() {
    },
  };

  // Hooks the component into the app
  app.registerComponent( 'announcement-banner', COMPONENT );
} )( app );
