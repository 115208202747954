/**
* Marquee JS
* -----------------------------------------------------------------------------
*
* All the JS for the Marquee component.
*/

/*
 * Example of importing modules if needed. Like for scroll magic / gsap
 */

// import {gsap} from 'gsap';
// import {ScrollTrigger} from 'gsap/ScrollTrigger.js';
// gsap.registerPlugin( ScrollTrigger );
import {Splide} from '@splidejs/splide';
import {AutoScroll} from '@splidejs/splide-extension-auto-scroll';
( function( app ) {
  const COMPONENT = {

    className: 'marquee',
    selector: function() {
      return '.' + this.className;
    },
    // Fires after common.init, before .finalize and common.finalize
    init: function() {
      if ( $( '.marquee__splide' ).length ) {
        $( '.marquee__splide' ).each( function( index, element ) {
          const textWidth = $( element ).find( '.marquee__slide-text' ).outerWidth();

          new Splide( element, {
            type: 'loop',
            fixedWidth: `${textWidth}px`,
            gap: '2rem',
            autoWidth: false,
            autoScroll: {
              speed: 0.75,
              pauseOnHover: false,
            },
            arrows: false,
            pagination: false,
          } ).mount( {AutoScroll} );
        } );
      }
    },
    finalize: function() {
    },
  };

  // Hooks the component into the app
  app.registerComponent( 'marquee', COMPONENT );
} )( app );
