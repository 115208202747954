/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

( function( app ) {
  const COMPONENT = {
    init: function() {
      // $( '.ll-mpf__continue-btn' ).on( 'click', function() {
      //   $( '.ll-mpf' ).addClass( 'is-started' );
      // } );

      // $( '.mpf-back-btn' ).on( 'click', function() {
      //   $( '.ll-mpf' ).removeClass( 'is-started' );
      // } );
      const pages = document.querySelectorAll( '.gform_page' );

      const $navCurrent = $( '.mpf-current-step' );
      const $navTotal = $( '.mpf-step-amount' );

      $( document ).on( 'gform_post_render', function( event, formId, currentPage ) {
        // Add event listener to gf_step_page_count span
        $( '.gf_step_page_count' ).on( 'DOMNodeInserted', function() {
          // Your code to execute when the step number changes
          const currentStep = $( '.gf_step_current_page' ).text();
          const totalSteps = $( '.gf_step_page_count' ).text();
          $navCurrent.text( currentStep );
          $navTotal.text( totalSteps );

          if ( currentPage > 1 ) {
            $( '.mpf-back-btn' ).addClass( 'is-hidden' );
          } else {
            $( '.mpf-back-btn' ).removeClass( 'is-hidden' );
          }
        } );
      } );
      $( document ).on( 'gform_confirmation_loaded', function( event, formId ) {
        $( '.ll-mpf' ).addClass( 'is-confirmation' );
      } );
      $( document ).on( 'gform_page_loaded', function( event, formId, currentPage ) {
      // center next button on .message-screen
        if ( $( '#gform_page_' + formId +'_' + currentPage ).hasClass( 'message-screen' ) ) {
          $( '.llgq-current-page .gform_next_button, .llgq-quoter-next, .gform_button[type="submit"]' ).addClass( 'block mx-auto' );
        } else {
          $( '.llgq-current-page .gform_next_button, .llgq-quoter-next, .gform_button[type="submit"]' ).removeClass( 'block mx-auto' );
        }
      } );
    },

    finalize: function() {
    },
  };

  app.registerComponent( 'll-mpf', COMPONENT );
} )( app );
