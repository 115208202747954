/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
( function( app ) {
  const COMPONENT = {
    init: function() {
      $( 'button.easy-toggle-slide-toggle' ).on( 'toggleBefore', function( event ) {
        const target = $( this ).data( 'toggle-target' );
        $( target ).slideToggle();
      } );

      $( 'button.easy-toggle-fade-toggle' ).on( 'toggleBefore', function( event ) {
        const target = $( this ).data( 'toggle-target' );
        $( target ).fadeToggle();
      } );

      $( document ).on( 'click', '.js-init-video', function( e ) {
        e.preventDefault();
        $.magnificPopup.open( {
          items: {
            src: $( this ).attr( 'href' ),
          },
          type: 'iframe',
          mainClass: 'mfp-fade',
          removalDelay: 160,
          preloader: false,
          fixedContentPos: false,
          callbacks: {
            open: function() {
              $( 'video' ).trigger( 'pause' );
            },
            close: function() {
              $( 'video' ).trigger( 'play' );
            },
          },
        } );
      } );

      $( '.js-init-content' ).magnificPopup( {
        type: 'inline',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: false,
      } );

      $( '.navbar-toggle' ).on( 'toggleAfter', ( event ) => {
        $( '#ll-body' ).toggleClass( 'is-open' );
      } );

      if ( !window.isMobile ) {
        $( '.primary-menu-item:first-of-type > button' ).trigger( 'click' );
        $( '.primary-menu-item:first-of-type > .child-menu > ul li:first-of-type button' ).trigger( 'click' );
      }

      $( document ).on( 'updateMediaQuery', ( event ) => {
        /*
         * Remove any inline display values when the screen changes
         * between mobile and desktop state. This allows the default
         * stylings to kick in and prevent any weird "half mobile half desktop"
         * nav display states that sometimes occur while resizing the browser
         * Also remove any active is-open classes from the toggle and nav to reset
         * its state when switching between screen sizes
         */
        $( '.primary-nav' ).get( 0 ).style.removeProperty( 'display' );
        $( '.navbar-toggle, .primary-nav' ).removeClass( 'is-open' );
      } );

      if ( $( '.form-skin .ll-file-upload' ).length > 0 && typeof( gform ) !== 'undefined' ) {
        $( 'input[type=\'file\']' ).change( function() {
          const fullPath = $( this ).val();
          let filename = fullPath.replace( /^.*[\\/]/, '' );
          if ( filename === '' ) {
            filename = 'No file chosen';
          }
          const theInput = $( this ).parent().find( '.ll-file-upload span' );
          $( theInput ).text( filename );
        } );
      }
    },
    finalize: function() {
    },
  };

  app.registerComponent( 'common', COMPONENT );
} )( app );
