/**
* Featured Services JS
* -----------------------------------------------------------------------------
*
* All the JS for the Featured Services component.
*/

/*
 * Example of importing modules if needed. Like for scroll magic / gsap
 */

// import {gsap} from 'gsap';
// import {ScrollTrigger} from 'gsap/ScrollTrigger.js';
// gsap.registerPlugin( ScrollTrigger );
( function( app ) {
  const COMPONENT = {

    className: 'featured-services',
    selector: function() {
      return '.' + this.className;
    },
    // Fires after common.init, before .finalize and common.finalize
    init: function() {
      cardContentPositioning();
      $( window ).on( 'resize', function() {
        cardContentPositioning();
      } );
      function cardContentPositioning() {
        if ( window.innerWidth > 768 ) {
          $( '.featured-services__card-content' ).each( function() {
            const hoverContent = $( this ).find( '.featured-services__card-hover-content' );
            const descriptionHeight = hoverContent.height();
            $( this ).css( 'transform', `translateY(${descriptionHeight}px)` );
          } );
        }
      }
    },
    finalize: function() {
    },
  };

  // Hooks the component into the app
  app.registerComponent( 'featured-services', COMPONENT );
} )( app );
